import React from "react"

import Layout from "@components/Section"
import Logo from "@components/Logo"
import { graphql } from "gatsby"
import SEO from "@components/seo"
import LanguageSwitch from "@components/LanguageSwitch"

const CookiesPolicy = ({ data }) => {
  const content = data.allMarkdownRemark.edges[0]

  return (
    <>
      <SEO title={content.node.frontmatter.title} />
      <Layout background="positive" className="pb-24" container>
        <div className="pt-16 mb-24 flex justify-between">
          <Logo width="32" baseColor="negative" circleColor="blue" />
          <LanguageSwitch />
        </div>
        <div dangerouslySetInnerHTML={{ __html: content.node.html }} />
      </Layout>
    </>
  )
}

export default CookiesPolicy

export const query = graphql`
  query GetCookiesPolicyContent($lang: String) {
    allMarkdownRemark(
      filter: {
        frontmatter: { id: { eq: "cookiesPolicy" }, lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            lang
          }
        }
      }
    }
  }
`
